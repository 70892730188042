import NavigationStore from './UI/NavigationStore';
import PhoneStore from './UI/PhoneStore';
import WindowStore from './UI/WindowStore';
import ScrollStore from './UI/ScrollStore';
import BlockDataStore from './UI/BlockDataStore';
import OnPageNavStore from './UI/OnPageNavStore';
import FormStore from './UI/FormStore';
import PagesStore from './PagesStore';
import PartnerStore from './PartnerStore';
import LocationsStore from './LocationsStore';
import ServiceStore from './ServiceStore';
import BrandStore from './BrandStore';
import TrackingStore from './TrackingStore';
import RouterStore from './UI/RouterStore';
import LocatorStore from './maps/LocatorStore';
import PortalStore from './PortalStore';
import wpapi from 'wpapi';
import ScrollManager from 'window-scroll-manager';
import { setHeaders, getApiconfig } from '../helpers/api';

const scrollManager = new ScrollManager();
const wpApi = new wpapi(getApiconfig());
setHeaders(wpApi);
const routerStore = new RouterStore();

const windowStore = new WindowStore();
const scrollStore = new ScrollStore(routerStore, windowStore, scrollManager);
const navigationStore = new NavigationStore(routerStore);
const locatorStores = new Map();

export const getNewLocatorStore = (key) => {
  if (!locatorStores.has(key)) {
    const newStore = new LocatorStore(wpApi);
    locatorStores.set(key, newStore);
    return newStore;
  } else {
    return locatorStores.get(key);
  }
};

export default {
  routerStore: routerStore,
  navigationStore: navigationStore,
  phoneStore: new PhoneStore(routerStore),
  blockDataStore: new BlockDataStore(routerStore, navigationStore),
  windowStore: windowStore,
  pagesStore: new PagesStore(wpApi, routerStore),
  partnerStore: new PartnerStore(wpApi, routerStore),
  locationsStore: new LocationsStore(wpApi, routerStore),
  serviceStore: new ServiceStore(wpApi, routerStore),
  brandStore: new BrandStore(wpApi, routerStore),
  trackingStore: new TrackingStore(wpApi, routerStore),
  portalStore: new PortalStore(wpApi, routerStore),
  scrollStore: scrollStore,
  onPageNavStore: new OnPageNavStore(),
  formStore: new FormStore(),
  // place for other stores...
};
