import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const hidePoiStyle = [
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'off' }],
  },
];

// FontAwesome fa-location-dot
const currentIcon = {
  path: 'M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z',
  fillColor: '#dd0000',
  strokeColor: '#660000',
  fillOpacity: 1,
  anchor: { x: 384 / 2, y: 512 },
  strokeWeight: 1,
  scale: 0.075,
  labelOrigin: { x: 384 / 2, y: 512 + 80 },
};

const intToHex = (num) => {
  const cleanedNum = Math.max(0, Math.min(255, Math.round(num)));
  return cleanedNum < 16 ? '0' + cleanedNum.toString(16) : cleanedNum.toString(16);
};
const shadeHexColor = (color, factor) => {
  if (typeof color !== 'string' || color.substring(0, 1) !== '#') {
    return color;
  }

  let r;
  let g;
  let b;

  if (color.length === 4) {
    r = parseInt(color.substring(1, 2) + color.substring(1, 2), 16);
    g = parseInt(color.substring(2, 3) + color.substring(2, 3), 16);
    b = parseInt(color.substring(3, 4) + color.substring(3, 4), 16);
  } else if (color.length === 7) {
    r = parseInt(color.substring(1, 3), 16);
    g = parseInt(color.substring(3, 5), 16);
    b = parseInt(color.substring(5, 7), 16);
  } else {
    return color;
  }

  const rHex = intToHex(factor * r);
  const gHex = intToHex(factor * g);
  const bHex = intToHex(factor * b);

  return '#' + rHex + gHex + bHex;
};

export const getIcon = (color, sizeFactor) => {
  const styledIcon = { ...currentIcon };

  if (color) {
    styledIcon.fillColor = color;
    styledIcon.strokeColor = shadeHexColor(color, 0.4);
  }
  if (sizeFactor) {
    styledIcon.scale = styledIcon.scale * sizeFactor;
  }

  return styledIcon;
};
const libraries = ['places'];

/* expects prop isVisible from VisibilitySensor */
const StyledMap = inject('blockDataStore')(
  observer(({ isVisible, ...props }) => {
    const getKey = () => {
      if (typeof window !== 'undefined' && typeof window.SN !== 'undefined' && window.SN.gapi) {
        return window.atob(window.SN.gapi);
      } else {
        return false;
      }
    };
    const apiKey = useRef(getKey());
    const [show, setShow] = useState(isVisible);
    const mapRef = useRef(false);

    useEffect(() => {
      if (!show && isVisible) {
        setShow(true);
      }
    }, [show, isVisible]);
    if (!apiKey) {
      return null;
    }
    const { isLoaded } = useJsApiLoader(
      {
        id: 'google-map-script',
        googleMapsApiKey: apiKey.current,
        libraries: libraries,
      },
      [libraries]
    );

    if (props.isVisible || show) {
      const propsStyles = props.options?.styles ? props.options.styles : [];

      return isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={15}
          clickableIcons={false}
          {...props}
          options={{
            ...props.options,
            styles: [...hidePoiStyle, ...propsStyles],
          }}
          onLoad={(map) => {
            mapRef.current = map;
            if (props.onLoad) {
              props.onLoad(map);
            }
          }}
          onUnmount={(map) => {
            // do your stuff before map is unmounted
          }}
        >
          {props.children}
        </GoogleMap>
      ) : (
        <div>&nbsp;</div>
      );
    } else {
      return <div>&nbsp;</div>;
    }
  })
);

export default StyledMap;
