import React from 'react';
import { Link } from 'react-router-dom';
import Img from '../../elements/Img/Img';
import { renderText } from '../../../helpers/api';
import { observer, inject } from 'mobx-react';

const PartnerBox = inject(
  'navigationStore',
  'windowStore'
)(
  observer((props) => {
    const isCurrentUrl = props.navigationStore.isCurrentUrl;
    const partner = props.partner;

    const getLogo = () => {
      const title = partner?.name;
      const alt = partner?.name;
      if (partner?.member) {
        const logoObj = partner.logo;
        if (logoObj) {
          return (
            <Img
              src={logoObj.url}
              width={logoObj.width}
              height={logoObj.height}
              className="img-fluid"
              alt={alt}
              title={title}
            />
          );
        } else {
          return null;
        }
      } else {
        return (
          <img
            className="img-fluid"
            src={'/content/themes/sanoa/assets/images/sanoa-logo-fallback.webp'}
            width={512}
            height={512}
            alt={alt}
            title={title}
          />
        );
      }
    };

    const getAddress = () => {
      return partner.address.map((row, i) => {
        return <div key={i}>{renderText(row)}</div>;
      });
    };

    // tracking
    const gaLocationLogoClassName = 'ga-' + partner.slug + '-page-partner-logo';
    const gaLocationNameClassName = 'ga-' + partner.slug + '-page-partner-name';
    const gaLocationTelefonClassName = 'ga-' + partner.slug + '-page-partner-telefon';
    const gaLocationEmailClassName = 'ga-' + partner.slug + '-page-partner-email';
    const gaLocationHomepageClassName = 'ga-' + partner.slug + '-page-partner-homepage';

    return (
      <div className={'ida-location-box'}>
        <div className="ida-location-box__content">
          <h3 id="location-box" className="-headline">
            {renderText(props.headerTitle)}
          </h3>
          <div className="d-flex">
            <div className="ida-location-box__logo">
              <Link className={gaLocationLogoClassName} to="/">
                {getLogo()}
              </Link>
            </div>
            <div className="ida-location-box__header">
              <h3 className="-title">
                <Link className={gaLocationNameClassName} to="/">
                  {renderText(partner?.name)}
                </Link>
              </h3>
              <div>{getAddress()}</div>
            </div>
          </div>
          <div className="ida-location-box__contact">
            {partner.phone && (
              <div className="d-flex">
                <h4>Telefon:</h4>
                <span> {partner.phone}</span>
              </div>
            )}
            {partner.email && (
              <div className="d-flex">
                <h4>E-Mail:</h4>
                <span> {partner.email}</span>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-wrap -cta">
          {partner.phone && (
            <h3>
              <a className={'-button phone ' + gaLocationTelefonClassName} href={'tel:' + partner.phone}>
                <span>Anrufen</span>
              </a>
            </h3>
          )}
          {partner.email && (
            <h3>
              <a className={'-button email ' + gaLocationEmailClassName} href={'mailto:' + partner.email}>
                <span>E-Mail senden</span>
              </a>
            </h3>
          )}
          {partner.website && !isCurrentUrl(partner.website) && (
            <h3>
              <a className={'-button website ' + gaLocationHomepageClassName} href={partner.website}>
                <span>Homepage</span>
              </a>
            </h3>
          )}
        </div>
      </div>
    );
  })
);

export default PartnerBox;
