import React, { useContext } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { renderText, getConfig, isPortalPage } from '../../../helpers/api';

const ConsentModal = observer((props) => {
  const { blockDataStore, trackingStore } = useContext(MobXProviderContext);

  if (!blockDataStore.isActiveTracking) {
    return null;
  }

  const onCLick = (option) => (e) => {
    trackingStore.saveConsentModalData(option);
    blockDataStore.onClickAnalyticsOption(option, e);
  };

  const config = getConfig('tracking');

  const text = config.mink_opt_tracking_text ? config.mink_opt_tracking_text : null;

  let footer = null;
  if (isPortalPage() && config.mink_opt_tracking_corporate_footer) footer = config.mink_opt_tracking_corporate_footer;
  else if (config.mink_opt_tracking_footer) {
    footer = config.mink_opt_tracking_footer;
  }

  const yes = config.mink_opt_tracking_yes ? config.mink_opt_tracking_yes : null;

  const no = config.mink_opt_tracking_no ? config.mink_opt_tracking_no : null;

  return (
    <Modal
      isOpen={blockDataStore.showConsentModal}
      fade={false}
      className={'mink-consent-modal'}
      backdrop={true}
      centered={true}
      id="consent"
      labelledBy="consent"
      aria-label="Privatsphäre Einstellungen"
    >
      <ModalBody className="mink-consent-modal__body">{renderText(text)}</ModalBody>
      <ModalFooter className="mink-consent-modal__footer px-0">
        <Button color="light" onClick={onCLick(false)}>
          {renderText(no)}
        </Button>
        <Button color="primary" onClick={onCLick(true)}>
          <span className="-text">{renderText(yes)}</span>
        </Button>
      </ModalFooter>
      <ModalBody className="mink-consent-modal__body">{renderText(footer)}</ModalBody>
    </Modal>
  );
});

export default ConsentModal;
