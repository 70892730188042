import React, { useEffect, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import { trackingClassName } from '../../../helpers/api';

const Footer = inject('phoneStore')(
  observer((props) => {
    const post = props.post;
    const trackingParams = {
      partnerSlug: post.partner.slug,
      postType: post.post_type,
      slug: post.slug,
    };

    const getPhone = useCallback(() => {
      if (props.phoneStore.currentFooterPhone) {
        return props.phoneStore.currentFooterPhone;
      }
      let newPhone = '';
      if (post.footer_phone) {
        newPhone = post.footer_phone;
      }
      if (post?.partner?.phone) {
        newPhone = post.partner.phone;
      }
      if (post?.location?.phone) {
        newPhone = post.location.phone;
      }
      return newPhone;
    }, [post, props.phoneStore.currentFooterPhone]);

    const getMail = useCallback(() => {
      let newMail = '';
      if (post.footer_email) {
        newMail = post.footer_email;
      }
      if (post?.partner?.email) {
        newMail = post.partner.email;
      }
      if (post?.location?.email) {
        newMail = post.location.email;
      }
      return newMail;
    }, [post]);

    useEffect(() => {
      getMail();
      getPhone();
    }, [getMail, getPhone]);
    const text = post.footer_text;
    const mail = getMail();
    const phone = getPhone();

    return (
      <footer className="mink-footer">
        <div className="mink-footer__item">{text}</div>
        <div className="mink-footer__item">
          <a href={'mailto:' + mail} className={'-icon -email ' + trackingClassName(trackingParams, 'footer-telefon')}>
            {mail}
          </a>
        </div>
        <div className="mink-footer__item">
          <a href={'tel:' + phone} className={'-icon -phone ' + trackingClassName(trackingParams, 'footer-email')}>
            {phone}
          </a>
        </div>
      </footer>
    );
  })
);

export default Footer;
