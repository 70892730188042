import React from 'react';
import UniLink from '../../components/elements/UniLink/UniLink';

const NoMatch = (props) => {
  return (
    <div className="ida-page ">
      <div className="ida-page__content container">
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2">
            <div style={{ textAlign: 'center' }}>
              <br />
              <br />
              <br />
              <br />
              <h3>Die Seite wurde leider nicht gefunden.</h3>
              <br />
              <UniLink href={props.noLocation ? 'https://sanoa.net/' : '/'}>
                <img
                  src="/content/themes/sanoa/assets/images/icon-filialen-klein5.png"
                  alt="Sanoa - Fehlerhafter Seitenaufruf"
                  width="33%"
                  border="0"
                />
              </UniLink>
              <br />
              <br />
              <br />
              {props.noLocation ? (
                <h3>
                  Besuchen Sie unsere <UniLink href="https://sanoa.net/">Sanoa Homepage</UniLink>
                </h3>
              ) : (
                <h3>
                  Besuchen Sie die <UniLink href="/">Standortseite</UniLink>
                </h3>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
